/* eslint-disable no-unused-vars */
import {
  ACCESS_TOKEN_KEY,
  USER_NAME,
  IS_TEMP_LOGIN
} from "./constants";
export const log = window.location.href.includes('localhost') ? console.log : () => {};

export const mergeObject = function(a, b) {
  for (let k in a) {
    a[k] = b[k] == null || b[k] == undefined ? a[k] : b[k];
  }
  return a;
};

export const getAccessToken = function() {
  return JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY));
};

export const removeToken = function() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const saveAccount = function(accountInfo) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accountInfo);
};

export const isRepeat = function(arr) {
  let setArr = Array.from(new Set(arr))
  return setArr.length == arr.length ? false : true;
}

export const prefixPad = function(num, n, pad = "0") {
  var len = num.toString().length;
  while (len < n) {
    num = pad + num;
    len++;
  }
  return num;
};

export const dateFormat = function(date, fmt) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

export const getUrlKey = function(name) {
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
};

export const getCodeApi = function(state) {
  let urlNow=encodeURIComponent(window.location.href);
  let scope='snsapi_base';    //snsapi_userinfo   //静默授权 用户无感知
  let AppId = 'wxab546a8c612b844a';
  let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.replace(url);
};

export default {
  log,
  mergeObject,
  isRepeat,
  prefixPad,
  dateFormat,
  getAccessToken,
  removeToken,
  saveAccount,
  getUrlKey,
  getCodeApi
};
