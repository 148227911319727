<template>
    <div class="container">
        <div class="navHeader">
            <div class="back el-icon-arrow-left" @click="back"></div>
            <div class="title">我要留言</div>
        </div>
        <div class="msgBox" v-if="type==0">
            <el-form :model="msgform" :rules="rules" ref="msgform">
                <div class="item-title"><span class="xred">*</span>留言分类</div>
                <div class="item-box">
                    <el-form-item :label-width="formLabelWidth">
                        <el-select v-model="msgform.deptId" prop='deptId' placeholder="请选择部门" style="width: 100%" @change="changeDeptId()">
                            <el-option :label="item.name" :value="item.id" v-for="(item,index) in clbm" :key="index"></el-option>
                        </el-select>
                        <!-- <el-select v-model="msgform.officeId" placeholder="请选择科室" style="width: 48%;float:right;">
                            <el-option :label="item.name" :value="item.id" v-for="(item,index) in officesArr" :key="index"></el-option>
                        </el-select> -->
                    </el-form-item>
                </div>
                <div class="item-title">
                    <span class="xred">*</span>
                    留言标题
                </div> 
                <div class="item-box">
                 <el-input
                    type="textarea"
                    style="width: 100%"
                    :rows="2"
                    placeholder="请输入留言标题"
                    v-model="msgform.title">
                    </el-input>
                </div> 
                <div class="item-title"><span class="xred">*</span>留言内容</div> 
                <div class="item-box">
                 <el-input
                    type="textarea"
                    style="width: 100%"
                    :rows="6"
                    placeholder="请输入留言内容"
                    v-model="msgform.content">
                    </el-input>
                </div>  
                <div class="btn">
                    <el-button  :loading="loading"  @click="submitForm('msgform')">提交</el-button>
                </div>                   
            </el-form>
           
        </div>
        <div class="success" v-else>
            <div class="tipsbox">
                <div class="img">
                    <el-image
                    style="width: 80px; height: 80px"
                    :src="success"
                    :fit="fill"></el-image>
                </div>
                <div class="tijiao">
                    已提交
                </div>
                <div class="tips">
                    留言已提交等待处理<br/>
                    请耐心等待…
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { getDeptApi , getOfficesApi,addleaveMsgApi } from "@/api/message/Msg"
      export default {
        data() {
            return {
                type:0,
                officesArr:[],
                clbm:[],
                formLabelWidth: '0px',
                rules: {
                    deptId: [
                       { required: true, message: '请选择部门', trigger: 'change' }
                    ],
                    officeId: [
                       { required: true, message: '请选择科室', trigger: 'change' }
                    ],
                    title: [
                        { required: true, message: '请输入留言', trigger: 'blur' },
                        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请填写留言内容', trigger: 'blur' }
                    ],                    
                },                
                msgform: {
                    deptId:'',
                    officeId:'',
                    title: '',
                    content: ''
                }, 
                loading:false,
                success:require('../assets/images/success.png'),             
            };
        },
        created: function () {
            document.title = "我要留言";
            this.getDeptLst();
        },
        methods: {
            back(){
                this.$router.go(-1);
            },
            async getDeptLst(){
               await getDeptApi({
                    type:1
                }).then(res=>{
                    if(res.code==0){
                        let resdata = res.data
                        resdata.forEach((item,index)=>{
                            item.check = false
                        })
                        this.clbm = [...this.clbm,...resdata];
                    }
                }).catch((response) => {
                    console.log(response)
                })
            },
            async changeDeptId(){
                await getOfficesApi({
                    deptId:this.msgform.deptId
                }).then(res=>{
                    if(res.code==0){
                        this.msgform.officeId = '';
                        this.officesArr = res.data;
                    }
                }).catch((response) => {
                    console.log(response)
                })
            },  
            async submitForm(formName){


                    if(!this.msgform.deptId ){
                        return this.$message.error({
                            message: '请选择部门',
                            type: 'success'
                        });
                    }
                   if(!this.msgform.title){
                        return this.$message.error({
                            message: '请输入留言标题',
                            type: 'success'
                        });
                    }
                   if(!this.msgform.content){
                        return this.$message.error({
                            message: '请输入留言内容',
                            type: 'success'
                        });
                    } 

					this.$confirm('确定要提交留言吗?', '', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
                        customClass: 'message-confirm',
						type: 'warning'
					}).then(() => {
                        this.loading = true;
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                addleaveMsgApi({
                                    deptId:this.msgform.deptId,
                                    officeId:this.msgform.officeId,
                                    title:this.msgform.title,
                                    content:this.msgform.content,
                                }).then(res=>{
                                    this.loading = false;
                                    if(res.code==0){
                                        this.type = 1;
                                        this.$message({
                                            message: '留言成功',
                                            type: 'success'
                                        });
                                    }else {
                                        this.$message(res.message);
                                    }
                                }).catch((response) => {
                                    this.loading = false;
                                });
                            } else {
                                this.loading = false;
                            }                            
                        });

					}).catch(() => {

                    });

            }                      
        }
    }
</script>


<style>
.message-confirm .el-message-box__title,.message-confirm .el-message-box__btns {
    text-align: center;
}
.message-confirm .el-message-box__status {
    display: none;
}
</style>

<style scoped>

.xred {
    color: red;
}


/deep/.el-input__inner {
    -webkit-appearance: none;
}

/deep/.el-button--default {
    width: 351px;
    height: 44px;
    background: #2C7ECE;
    border-radius: 6px;
    color: #fff;
}

/deep/.el-input__inner {
    -webkit-appearance: none;
}

/deep/.el-input__inner:hover,/deep/.el-input__inner:focus {
    border: 1px solid #2C7ECE;
}
/deep/ .el-select .el-input.is-focus .el-input__inner{
    border-color: #2C7ECE;
} 
/deep/ .el-textarea__inner {
    box-shadow:none;
    border: 1px solid #EAEFF3;
}
/deep/.el-textarea__inner:hover,/deep/.el-textarea__inner:focus {
    border: 1px solid #2C7ECE;
} 

/deep/.el-button--primary {
    background: #2C7ECE;
}

/deep/.el-button--default:hover,/deep/.el-button--default:focus {
    background: #2C7ECE;
    color: #fff;
}  
</style>

<style lang="scss">

@media screen and (max-width: 500px) {
    .el-message-box{
      width: 300px !important;
    }
    .el-button--primary {
        background: #2C7ECE !important;
    }
  }

   .navHeader {
       position: relative;
       height: 44px;
       line-height: 44px;
       text-align: center;
       background: #fff;
       .back {
           position: absolute;
           left:10px;
           top:10px;
           font-size:26px;
       }
       .title {
            font-size: 18px;
            font-weight: 600;
       }
   }
   .msgBox {
       padding:20px 20px;
   }
   .item-title {
       font-size: 12px;
       color: #AFAFAF;
       padding: 10px 0;
   }
   .btn {
       margin-top:30px;
   }

   .tipsbox {
       width: 160px;
       margin: 60px auto 0;
       text-align: center;
       .tijiao {
            font-weight: 600;
            color: #222222;
            line-height: 33px;
            font-size: 24px;
            margin-top:25px;
       }
       .tips {
            margin-top:25px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(34, 34, 34,0.6);
            text-align: center;
       }
   }
</style>