import axios from 'axios'
import {Message} from 'element-ui'

// 自己创建一个axios对象
const request = axios.create({
    baseURL: '/',   // 基础路径，默认是/ ，如果改了，会自动添加到你请求url前面
    timeout: 5000   // 请求超时，5000毫秒
})

// 请求拦截器
request.interceptors.request.use( config => {
    // 在发送请求之前做些什么
    return config;
  }, function (error) {
    // 处理请求错误
    return Promise.reject(error);
  });

// 相应拦截器
request.interceptors.response.use( response => {
    // 在2xx范围内的任何状态代码都会触发此功能
    // 处理响应数据
    return response;
  }, function (error) {
    // 任何超出2xx范围的状态代码都会触发此功能
    // 处理响应错误
    return Promise.reject(error);
  });



// 添加响应拦截器
request.interceptors.response.use(function (res) {
    // 对响应数据做点什么
     if (res && res.data && res.status==200 && res.data.code==0) {
        return  res.data
     }else{
        return Promise.reject(res.data)
    }}, function (error) {
       // 对响应错误做点什么;没有权限401，去登录界面
       if(error.response.status === 101) {
              Message.error('登录超时')
            //   setTimeout(() => {
            //       uni.switchTab({  
            //           url: '/pages/home/home'
            //       })
            //   },1000)
          }else if(error.response.status === 403) {
            Message.error('暂无权限')
          }else {
            Message.error('网络错误')
          }
  
    return Promise.reject(error);
   });

export default request  // 导出自定义创建的 axios 对象