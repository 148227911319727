import { BaseUrl} from "@/common/config";
import request from "@/utils/request";


export const getOpenIdApi = function(params) {
  return request({
    url: BaseUrl + "wei/getAccessToken",
    method: "post",
    data: params
  });
};

export const loginApi = function(params) {
  return request({
    url: BaseUrl + "student/login",
    method: "post",
    data: params
  });
};

export default {
  getOpenIdApi,
  loginApi
}