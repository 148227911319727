import Vue from 'vue'
import App from "./App";
import router from "./router";
import ElementUI from 'element-ui';

import '@/assets/scss/theme/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
    render: h => h(App)
})
