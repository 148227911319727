<template>
    <div id="app" class="page">
        <router-view  v-if="isRouterAlive"></router-view>
    </div>
</template>


<script>
    export default {
        name: 'App',
        data() {
            return {
                isRouterAlive: true
            }
        },
        provide () {                                               
            return {
                reload: this.reload                                              
            }
        },       
        methods: {
            reload () {
                this.isRouterAlive = false;            //先关闭，
                this.$nextTick(function () {
                    this.isRouterAlive = true;         //再打开
                }) 
            }
        }
    }
</script>
<style lang="scss">
    @import url("assets/scss/common.scss");
</style>
