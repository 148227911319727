
export const getUrlKey = function(name) {
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
};

export const getCodeApi = function(state) {
  let urlNow=encodeURIComponent(window.location.href);
  let scope='snsapi_base';    //snsapi_userinfo   //静默授权 用户无感知
  let AppId = 'wxab546a8c612b844a'; // 菁星校园公众号
  // let AppId = 'wx4974dc9f56c0c001'; // 淮南师范公众号
  let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.replace(url);
};

export default {
  getUrlKey,
  getCodeApi
};
