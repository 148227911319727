import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '../views/HelloWorld'
import Home from '@/views/Home'
import Message from '@/views/Message'
import Auth from '@/views/Auth'
import Login from '@/views/Login'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/message',
            name: 'Message',
            component: Message
        },
        {
            path: '/auth',
            name: 'Auth',
            component: Auth
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        }          
    ]
})
