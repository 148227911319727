<template>
    <div class="container">
        

    </div>
</template>

<script>
    import { getUrlKey,getCodeApi } from "@/common/wx";
    import { saveAccount , log } from "@/common/utils"
    import { getOpenIdApi , getUserByOpenId ,loginApi } from "@/api/user/user"

      export default {
        data() {
            return {
               openid:'',
               code:'',
            };
        },
        created: function () {
            document.title = "自动登录";
            let code=getUrlKey("code");             //获取url参数code
            // let code = '051CN4100ZzYQL1dHp100UlDTO2CN41k';
            if(code){     
                this.code = code;  
                this.authOpenid();
            }else{
                getCodeApi("123");
            }            
        },
        methods: {
            async authOpenid() {
                getOpenIdApi({
                    code:this.code
                }).then(res=>{
                    this.openid = res.data.openid
                    if(res.data.flag){
                        this.wxLogin();
                    } else {
                        let url ='login';
                        if(res.data.openid){
                            url = url+'?openid='+res.data.openid;
                        }
                        this.$router.push({path: url});
                    }
                }).catch(err=>{
                    log(err);
                })
            }, 
            async wxLogin() {
                loginApi({
                    username:'',
                    password:'',
                    openid:this.openid,
                }).then(res=>{
                    if(res.code==0){
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                        let user = JSON.stringify(res.data.userInfo);
                        saveAccount(user);
                        this.$router.push('/');                        
                    } else {
                        this.$message.error("登录失败");
                    }

                }).catch(e=>{
                    this.$message.error(e.message);
                   log(e);
                })
            }            

        }
    }
</script>