<template>
  <div class="container">
        <div class="tavnav">
            <ul>
                <li :class="curr==1 ? 'curr':''" @click="tavnav(1)">处理中({{dototal}})</li>
                <li :class="curr==2 ? 'curr':''" @click="tavnav(2)">历史({{histotal}})</li>
            </ul>
        </div>
        <div class="wrap">
            <div class="msgItems" id="resultScroll" ref="resultScroll" v-if="curr==1"  style="height: 700px;overflow:auto">
                <div class="items" v-for="(vo,index) in msgLst" :key="index">
                    <div class="header">
                        {{vo.title}}
                    </div>
                    <div class="info">
                        <div class="date">留言日期：{{vo.addTime}}</div>
                        <div class="bumeng">留言部门:{{vo.extend.deptName}} -  {{vo.extend.officeName}}</div>
                    </div>
                    <div class="lycontent">
                         {{vo.content}}
                        <!-- <a href="">查看全部</a> -->
                    </div>
                    <div class="lyclArea">
                        <el-button type="info" >处理中</el-button>
                    </div>          

                </div>
            </div>
            <div class="history" id="resultScroll" ref="resultScroll" style="height: 700px;overflow:auto" v-else>
                 <div class="items" v-for="(vo,index) in historyLst" :key="index">
                    <div class="header">
                       {{vo.title}}
                    </div>
                    <div class="info">
                        <div class="date">留言日期：{{vo.addTime}}</div>
                        <div class="bumeng">留言部门:{{vo.extend.deptName}} -  {{vo.extend.officeName}}</div>
                    </div>
                    <div class="lycontent">
                        {{vo.content}}
                        <!-- <a href="">查看全部</a> -->
                    </div>
                    <div class="lyclArea">
                        <el-button type="warning" v-if="vo.status==2" style="margin-bottom:10px">不予处理</el-button>
                        <el-button type="primary" v-if="vo.status==1" style="margin-bottom:10px">已处理</el-button>
                        <div class="ly_content"  v-if="vo.status==1">
                            <div class="bumen-info">
                                <div class="bumen">{{vo.extend.handleDeptName}} - {{vo.extend.handleOfficeName}}</div>
                                <div class="ly_time">处理时间：{{vo.handleTime}}</div>
                            </div>
                            <div class="ly_content_msg">
                               {{vo.handleContent}}
                            </div>
                        </div>
                    </div> 
                </div>              
            </div>
            <div class="empty" v-if="msgLst.length==0 && curr==1 || historyLst.length==0 && curr==2">
                <el-image
                style="width: 330px; height: 170px;"
                :src="emptyImg"
                fit="fill"></el-image>
            </div>            
        </div>
        <div class="block80"></div>
        <div class="footer" @click="gopage('message')">
            <div class="btn">我要留言</div>
        </div>
  </div>
</template>

<script>
import { getMsgLstApi  } from "@/api/message/Msg"
import { log,getAccessToken } from "@/common/utils";
    export default {
        data() {
            return {
                curr:1,
                msgLst:[],
                historyLst:[],
                page:1,
                pageSize:5,
                user:{},
                emptyImg:require('../assets/images/empty.png'),
                dototal:0,
                histotal:0,
                REQUIRE: true,
                loading: true,
                tips: '努力加载中...'                
            };
        },
        created: function () {
            document.title = "自助服务";
            this.getMsgLst();
            this.getHistoryMsgLst();        
            this.user = getAccessToken();           
            if(!this.user){
                this.$router.push('login');
            }   
        },
        mounted () {
            let that = this
             // 监听滚动事件
             document.getElementById('resultScroll').addEventListener('scroll', that.handleScroll,true)      
           
        },        
        methods: {
           tavnav(num){
             this.curr = num
             this.page = 1;
           },
           gopage(url){
                this.$router.push({path:url});
           },
           handleScroll(){
                let that = this
                let sh = that.$refs['resultScroll'].scrollHeight // 滚动条高度
                let st = that.$refs['resultScroll'].scrollTop // 滚动条距离顶部的距离
                let ch = that.$refs['resultScroll'].clientHeight // 滚动条外容器的高度
                if (st + ch >= sh) {
                    //到底了-业务逻辑
                    that.page += 1
                    if(this.curr==1){
                        if(this.msgLst.length< this.dototal){
                            that.getMsgLst()
                        }
                    } else {
                        if(this.historyLst.length< this.histotal){
                            that.getHistoryMsgLst()
                        }
                    }
                }
            },                               
            getMsgLst(){
                let _this = this;
                let user = getAccessToken()
                 getMsgLstApi({
                        flag:1,
                        page:this.page,
                        pageSize:this.pageSize,
                        userId:user.id
                    }).then(res=>{
                        if(res.code==0){
                            let resdata = res.data
                            this.msgLst = [..._this.msgLst,...resdata.list]
                            this.dototal = resdata.total
                            if(_this.curr==1 && _this.msgLst.length>=resdata.total){
                                _this.loading = false
                                _this.tips = '';
                            } else {
                               _this.loading = true
                            }
                        }
                    }).catch(err=>{
                        if(err.code==501){
                            _this.$router.push({path:'auth'});
                        }
                        _this.loading = false
                    });
            },  
            async getHistoryMsgLst(){
                let user = getAccessToken()
                try {
                   await getMsgLstApi({
                        flag:2,
                        page:this.page,
                        pageSize:this.pageSize,                        
                        userId:user.id
                    }).then(res=>{
                        if(res.code==0){
                            let resdata = res.data
                            this.historyLst = [...this.historyLst,...resdata.list]
                            this.histotal = resdata.total

                            if(this.curr==2 && this.histotal>=this.historyLst.length){
                                this.loading = false
                                this.tips = '';
                            } else {
                                this.page++;
                               this.loading = true
                            }
                        }
                    }).catch(err=>{
                        if(err.code==501){
                            this.$router.push({path:'auth'});
                        }
                        this.loading = false
                    });
                } catch (error) {
                    log(error);
                    this.loading = false
                }
            },                     
        }
    }
</script>


<style scoped>
/deep/ .el-button {
    width: 80px;
    height: 30px;
    padding: 0px;
    border: none;
    border-radius:0px;
}
/deep/ .el-button--primary {
    background: #4A90E2;
}
/deep/ .el-button--info {
    background: #D4D4D4;
}
/deep/ .el-button--warning {
    background: #FF5353;
}
</style>

<style lang="scss">
body {
    background: #F3F5F7;
}
.tavnav ul {
    display: flex;
    li {
        width: 50%;
        height: 44px;
        background: #FFFFFF;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #AFAFAF; 
        line-height: 44px;   
        &.curr{
            color: #000000;
            font-weight: 600;
        }    
        &.curr::after{
            position: absolute;
            bottom: 0px;
            left: 40%;
            content: '';
            width: 18px;
            height: 4px;
            background: #2C7ECE;
        }
    }
}

.items {
    width: 93.6%;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 1rem auto 0;
    padding:20px;
    .header {
        font-size:16px;
        position: relative;
        &::after{
            position: absolute;
            left:-20px;
            top:0.5px;
            width: 6px;
            height: 20px;
            background: #2C7ECE;
            content:'',
        }
    }
    .info {
        padding-top:10px;
        font-size: 14px;
        color: #AFAFAF;
        line-height: 23px;
    }
    .lycontent {
        color: #222222;
        font-size: 14px;
        padding: 10px 0;
        line-height: 23px;
        a{
            color: #4A90E2;
        }
    }

    .lyclArea {
        .ly_content {
            background: #F3F5F7;
            padding: 10px 20px;
            .bumen-info {
                .bumen {
                    font-size: 18px;
                    font-weight: 600;
                    color: #4A90E2;
                }
                .ly_time {
                    padding: 15px 0;
                    color: #AFAFAF;                    
                    font-size: 14px;
                }
            }
            .ly_content_msg {
                font-size: 14px;
                line-height: 23px;
            }
        }
    }

}

.block80 {
    height: 80px;
    overflow: hidden;
}

.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 68px;
    padding: 15px 0;
    background: #FFFFFF;
    .btn {
        width: 93%;
        height: 44px;
        background: #2C7ECE;
        border-radius: 6px;
        margin: 0 auto;
        color: #fff;
        text-align: center;
        font-size:16px;
        line-height: 44px;
    }
}
.empty{
    width: 330px; 
    height: 170px;
    position: absolute;
    top:40%;
    left:30%;
    margin-top: -150px;
    margin-left: -80px;
}

.infinite-scroll {
    width: 100%;
    font-size: 12px;
    text-align: center;
    padding: 20px 0;
}
</style>
