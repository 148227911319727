<template>
    <div class="container">
        <div class="LoginArea">
            <el-row>
                <el-col :span="24">
                    <el-input id="name" v-model="username" placeholder="请输入帐号">
                        <template slot="prepend">帐号</template>
                    </el-input> 
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-input id="password" v-model="password" type="password" placeholder="请输入密码">
                        <template slot="prepend">密码</template>
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-button id="login" v-on:click="wxLogin" style="width:100%" type="primary">登录</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>


<style lang='scss' scoped>
.LoginArea {
    padding: 20px;
}
.el-row {
 margin-bottom: 20px;
 &:last-child {
  margin-bottom: 0;
 }
 }
.login-box {
    margin-top:20%;
    margin-left:40%;
}
</style>

<script>
    import { saveAccount , log } from "@/common/utils"
    import { loginApi } from "@/api/user/user"
      export default {
        data() {
            return {
               openid:'',
               username:'',
               password:'',
            };
        },
        created: function () {
            document.title = "登录";
            this.openid = this.$route.query.openid      
        },
        methods: {
            wxLogin() {
                if(!this.username || !this.password){
                    return this.$message("账户或密码不能为空");
                }
                loginApi({
                    username:this.username,
                    password:this.password,
                    openid:this.openid,
                }).then(res=>{
                    if(res.code==0){
                        let user = JSON.stringify(res.data.userInfo);
                        saveAccount(user);
                        this.$router.push('/');                        
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(e=>{
                    console.log(e)
                    this.$message.error(e.message);
                })
            }            

        }
    }
</script>